<template>
  <div style="background: #f6f6f6; height: 100%;">
    <van-nav-bar title="商品详情" fixed placeholder @click-left="onClickLeft" left-arrow />

    <div class="container">
      <div class="container-swipe">
        <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
          <van-swipe-item v-for="(x, i) in picUrls" :key="i">
            <van-image lazy-load fit="cover" :src="x" />
          </van-swipe-item>
        </van-swipe>

        <div class="xianshiActive" v-if="$route.query.ticketActive && !affiliation">
          <div class="xianshiActive-left">
            <img class="xianshiActive-left-img" src="./img/xszk.png" alt="">
            <img class="xianshiActive-left-img2" src="./img/ld.png" alt="" v-if="activeStrsus == '1'">
            <span class="xianshiActive-left-start" v-if="activeStrsus == '1'">即将开始</span>
            <span class="xianshiActive-left-start" v-if="activeStrsus == '3'">已结束</span>
          </div>
          <div class="xianshiActive-right" v-if="activeStrsus == '2'">
            <span class="xianshiActive-right-jieshu">距结束：</span>
            <van-count-down :time="time" @finish="finish">
              <template #default="timeData">
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div>

      <div class="container-info box">
        <div class="ticket" v-if="!$route.query.affiliation && !$route.query.ticketActive && coupons.length">
          <div class="ticket-left">
            <span class="ticket-left-item" v-for="x in coupons" :key="x.id">
              <van-tag plain color="#E54320" size="medium">{{ x.name }}</van-tag>
            </span>
          </div>
          <div class="ticket-right">
            <van-button round color="#E54320" size="mini" @click="lingqv">
              <span style="font-size: 12px;">领取</span>
              <van-icon name="arrow" />
            </van-button>
          </div>
        </div>

        <p class="container-info-title">{{ detail.name }}</p>
        <div class="container-info-price">
          <span style="color: #E54320; font-size: 0.3784rem;">￥</span>
          <span style="color: #E54320; font-size: 0.7568rem;">{{ skus.salesPrice }}</span>
          <span class="container-info-price-quanity" style="font-size: 0.3243rem; color: #999; float: ;">已售{{detail.saleNum }}件</span>
        </div>
        <p class="container-info-originalPrice">￥{{ skus.marketPrice }}</p>
      </div>
      <!-- 新增商品评价 -->
      <div v-if="goodsEvaluateList && goodsEvaluateList.length > 0" class="container-evaluateList">
        <div class="container-evaluateList-header">
          <p>商品评价 <span>({{ goodsSum }})</span></p>
          <div class="container-evaluateList-header-name" @click="enterEvaluate">
            <span>查看全部</span>
            <span>
              <van-icon name="arrow" size="mini" />
            </span>
          </div>
        </div>
        <div v-if="goodsEvaluateList && goodsEvaluateList.length > 0" class="container-evaluateList-body">
          <div v-for="(val) in goodsEvaluateList" :key="val.id" class="container-evaluateList-body-every">
            <div class="container-evaluateList-body-every-top">
              <div>
                <van-image round width="35" height="35" fit="cover" :src="val.headimgUrl" />
              </div>
              <div class="container-evaluateList-body-every-top-name">
                <p class="container-evaluateList-body-every-top-name-one">{{ val.nickName }}</p>
                <p>{{ val.diffTime }}</p>
              </div>
            </div>
            <div class="container-evaluateList-body-every-main">
              <p class="container-evaluateList-body-every-main-top">整体评价：{{ val.content || '该用户未作出评价' }}</p>
              <div v-if="val.picUrlsList && val.picUrlsList.length > 0"
                class="container-evaluateList-body-every-main-img">
                <van-image v-for="(item, index) in val.picUrlsList" :key="index"
                  class="container-evaluateList-body-every-main-img-one" width="2rem" height="2rem" fit="contain"
                  :src="item ? item : require('../../assets/img/touxiang.png')"
                  @click="previewImg(val.picUrlsList, index)" />
              </div>
              <p v-if="val.sellerReply" class="container-evaluateList-body-every-main-bottom">商家回复：{{ val.sellerReply
              }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-shopList">
        <div class="container-shopList-header">
          <span>
            <van-image radius="5" fit="cover" width="30" height="30" :src="shopInfo.imgUrl" />
          </span>
          <!-- 店铺信息 -->
          <div class="container-shopList-header-name">
            <div class="container-shopList-header-name-one">
              <div>
                <p class="container-shopList-header-name-one-platform">{{ shopInfo.name }}</p>
                <!-- <div class="container-shopList-header-name-one-platform">{{ shopInfo.detail }}</div> -->
              </div>
              <div class="container-shopList-header-name-one-arrow" @click="enterStore">
                <span>进入店铺</span>
                <span>
                  <van-icon name="arrow" size="mini" />
                </span>
              </div>
            </div>
            <div class="container-shopList-header-name-two">
              <!-- <span>{{ shopInfo.grade }}???</span> -->
              <div v-show="shopGrade == 0">
                <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index"
                  src="./img/xing.png" alt="">
              </div>
              <div v-show="0 < shopGrade && shopGrade < 6">
                <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index"
                  src="./img/zuan.png" alt="">
              </div>
              <div v-show="5 < shopGrade && shopGrade < 11">
                <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index"
                  src="./img/blueGuan.png" alt="">
              </div>
              <div v-show="10 < shopGrade">
                <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index"
                  src="./img/guan.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="commodityDetail">
      <div class="commodityDetail-title">
        <div></div>
        <span>商品详情</span>
        <div></div>
      </div>
      <div class="commodityDetail-img" v-html="detail.description">
      </div>
    </div>
    <div class="vanGoodsAction">
      <van-goods-action>
        <van-goods-action-icon icon="shop-o" text="店铺" @click="enterStore" />
        <van-goods-action-icon icon="service-o" text="客服" @click="contact" />
        <van-goods-action-icon icon="shopping-cart-o" text="购物车" @click="enter_cart" />
        <van-goods-action-button class="addCart" type="warning" text="加入购物车" @click="goumai_gouwuche(1)"
          :disabled="disabled" />
        <van-goods-action-button color="#E54320" type="danger" text="立即购买" @click="goumai_gouwuche(2)" />
      </van-goods-action>
    </div>

    <!-- 规格 -->
    <gui-ge ref="gui_ge" :show="show" :type="type" />

    <ticket-list 
      :ticketBoolean="ticketBoolean" 
      :coupons="coupons" 
      :riceivedCoupons="riceivedCoupons" 
      @updatePage="updatePage"
      @closeTicket="closeTicket" />

    <loading :loading="loading" @closeLoading="closeLoading" />

  </div>
</template>

<script>
import { ydCommldityDetail, goodsEvaluate, shopEvaluate, getTickerList } from '@/api/flMall'
import { getStoreDetail } from '@/api/detail'
import GuiGe from './actionSheet/GuiGe'
import Loading from './actionSheet/Loading'
import TicketList from "./actionSheet/TicketList"
import { contact } from "@/common"
import { Toast, ImagePreview } from "vant";
export default {
  components: {
    GuiGe,
    Loading,
    TicketList
  },

  data() {
    return {
      evaluateInfo: {
        size: 10,
        current: 1
      },
      detail: {},
      skus: {},
      picUrls: [],
      shopInfo: {},
      shopEvaluate: {},//店铺评分
      shopGrade: 0,
      gradeList: [],//店铺星星颗数
      goodsEvaluateList: [{}, {}],//商品评价
      goodsSum: 0,//商品评价总数
      show: false,
      type: 1,
      loading: false,
      disabled: false,
      affiliation: '',
      coupons: [],
      riceivedCoupons: [],
      ticketBoolean: false,
      timeData: {
        hours: '',
        minutes: '',
        seconds: ''
      },
      time: 0,
      activeStrsus: '3', // 1 未开始 2 进行中 3 已结束
    }
  },

  created() {
    if (this.$route.query.session) {
      this.$store.commit('setSession', this.$route.query.session)
    }
    if (this.$route.query.affiliation) {
      this.disabled = true
      this.affiliation = this.$route.query.affiliation
    }
  },

  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    this.getPage()
    this.$store.commit('set_liveRecordsId', this.$route.query.liveId)
  },

  methods: {
    //预览图片
    previewImg(data, index) {
      ImagePreview({ images: [...data], startPosition: index, closeable: true, })
    },
    getPage() {
      this.loading = true
      const id = this.$route.query.id
      ydCommldityDetail(id).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$refs.gui_ge.getPage()
          this.detail = res.data.data
          this.picUrls = res.data.data.picUrls
          this.skus = res.data.data.skus[0]
          this.shopInfo = res.data.data.shopInfo
          if (res.data.data.activeSpu) {
              // 当前时间 时分秒
              const date = new Date()
              const h = date.getHours()
              const m = date.getMinutes()
              const s = date.getSeconds()

              // 当前数据 开始时分秒
              const activeStartTime = res.data.data.activeStartTime
              const h2 = activeStartTime.split(':')[0]
              const m2 = activeStartTime.split(':')[1]
              const s2 = activeStartTime.split(':')[2]

              // 当前数据 结束时分秒
              const activeEndTime = res.data.data.activeEndTime
              const h3 = activeEndTime.split(':')[0]
              const m3 = activeEndTime.split(':')[1]
              const s3 = activeEndTime.split(':')[2]

              const r = Number(h * 3600) + Number(m * 60) + Number(s)
              const r2 = Number(h2 * 3600) + Number(m2 * 60) + Number(s2)
              const r3 = Number(h3 * 3600) + Number(m3 * 60) + Number(s3)

              if (r < r2) {
                this.activeStrsus = '1'
              } else if (r >= r3) {
                this.activeStrsus = '3'
              } else if (r >= r2) {
                this.time = (r3 - r) * 1000 || 0
                this.activeStrsus = '2'
              }
            } else {
              this.activeStrsus = '3'
            }
          //星钻皇冠
          this.getStoreDetail({ shopId: res.data.data.shopId, size: 10, current: 1 })
          this.getTickerList({ shopId: res.data.data.shopId, spuId: res.data.data.id })
        }
      }).catch(() => {
        Toast.fail("加载失败请重试");
        this.loading = false
      });
      this.getGoodsEvaluate({ spuId: id, size: 10, current: 1 })
    },
    //星钻皇冠
    getStoreDetail(params) {
      getStoreDetail(params).then(res => {
        if (res.data.code === 0) {
          this.shopGrade = res.data.data.shopInfo.grade || 0
          if (this.shopGrade <= 0 || this.shopGrade == null) {
            for (let index = 0; index < 5; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
          if (this.shopGrade > 0 && this.shopGrade < 6) {
            let strGrade = this.shopGrade
            for (let index = 0; index < strGrade; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
          if (this.shopGrade > 5 && this.shopGrade < 11) {
            let strGrade = this.shopGrade - 5
            for (let index = 0; index < strGrade; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
          if (this.shopGrade > 10) {
            let strGrade = this.shopGrade - 10
            for (let index = 0; index < strGrade; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
        }
      }).catch(() => {
        Toast.fail("加载失败请重试");
      })
    },
    //店铺
    enterStore() {
      this.$router.push({
        path: '/ydMallStoreDetail',
        query: {
          id: this.shopInfo.id,
          type: 'h5',
          affiliation: this.$route.query.affiliation
        }
      })
    },
    //获取评价
    getGoodsEvaluate(data) {  //使用type判断是展示全部还是只展示3个评价
      goodsEvaluate(data).then(res => {
        if (res.data.code == 0) {
          this.goodsSum = res.data.data.total
          this.goodsEvaluateList = res.data.data.records.length < 3 ? res.data.data.records : res.data.data.records.slice(0, 3)
          this.goodsEvaluateList = this.goodsEvaluateList.map(v => {
            if (v.picUrls) {
              v.picUrlsList = v.picUrls.split(',')
            }
            return v
          })
        }
      }).catch(() => {
        Toast.fail("加载失败请重试");
      });
    },
    //获取店铺评分
    getShopEvaluate(data) {
      shopEvaluate(data).then(res => {
        if (res.data.code == 0) {
          this.shopEvaluate = res.data.data ? res.data.data : {}
        }
      }).catch(() => {
        Toast.fail("加载失败请重试");
      });
    },
    //进入评价列表
    enterEvaluate() {
      this.$router.push({
        path: "/goodsEvaluateDetail",
        query: {
          id: this.$route.query.id,
          type: "h5",
        }
      })
    },

    goumai_gouwuche(type) {
      this.type = type
      this.show = true
    },
    //购物车
    enter_cart() {
      this.$router.push({
        path: '/ydMallCart',
        query: {
          type: 'h5',
          session: this.$route.query.session,
          affiliation: this.$route.query.affiliation,
          ticketActive: this.$route.query.ticketActive,
          advertisementId: this.$route.query.advertisementId,
          advertisementType: this.$route.query.advertisementType
        }
      })
    },
    //客服
    contact() {
      contact(this.shopInfo.imAccount.accid)
    },

    onClickLeft() {
      if (!this.isShow) {
        this.isShow = true
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up")
        }
        if (isAndroid) {
          window.android.close()
        }
      }
    },

    getTickerList (params) {
      getTickerList(params).then(res => {
        if (res.data.code === 0) {
          this.coupons = res.data.data.coupons
          this.riceivedCoupons = res.data.data.riceivedCoupons
        }
      })
    },

    lingqv () {
      this.ticketBoolean = true
    },

    closeTicket () {
      this.ticketBoolean = false
    },

    updatePage () {
      this.getPage()
    },

    finish () {
      setTimeout(() => {
        this.getPage()
      }, 1500)
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  .van-nav-bar__left {
    padding: 0 15px;
  }
}
.my-swipe .van-swipe-item {
  height: 10.1351rem;
  .van-image {
    width: 100%;
    height: 100%;
  }
}
.container-swipe {
  position: relative;
  .xianshiActive {
    width: 100%;
    background: linear-gradient( #ef571b, #ac4727);
    border-radius: 8px 8px 0 0;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    &-left {
      display: flex;
      align-items: center;
      &-img {
        width: 70px;
        margin-right: 10px;
      }
      &-img2 {
        width: 10px;
      }
      &-start {
        font-size: 14px;
        color: #fff;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      &-jieshu {
        font-size: 14px;
        color: #fff;
      }
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #ee0a24;
      }
      .block {
        display: inline-block;
        width: 22px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: linear-gradient(#FA9605, #FF5300);
        border-radius: 2px;
      }
    }
  }
}

.box {
  padding: 0.4054rem;
  background: white;
}

.container-info {
  &-title {
    font-size: 0.4595rem;
    color: #333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &-price {
    &-quanity {
      float: right;
      transform: translateY(0.4054rem);
    }
  }

  &-originalPrice {
    font-size: 0.3243rem;
    color: #999;
    text-decoration: line-through;
  }
  .ticket {
    display: flex;
    align-items: center;
    &-left {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: auto;
      &-item {
        margin-right: 3px;
        flex-shrink: 0;
      }
    }
    &-left::-webkit-scrollbar {
      display: none;
    }
    &-right {
      transform: translateY(-2px);
      box-shadow: -2px 0 9px 3px #fff;
      .van-button--mini {
        padding: 0 6px;
      }
    }
  }
}

.container-evaluateList {
  margin-top: 0.2703rem;
  padding: 0.1351rem 0.1351rem;
  background: #fff;

  &-header {
    display: flex;
    padding: 4px 0;
    margin-bottom: 3px;
    justify-content: space-between;
    align-items: center;

    &-name {
      display: flex;
      align-items: center;
    }

    &-name span:first-child {
      margin-right: 0.1351rem;
    }
  }

  &-body {
    padding: 4px 0;

    &-every {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #e9e9e9;

      &-top {
        display: flex;
        align-items: center;

        &-name {
          margin-left: 10px;
          font-size: 12px;
          display: flex;
          flex-direction: column;

          &-one {
            font-weight: 600;
          }
        }
      }

      &-main {
        font-size: 12px;
        padding: 0 5px;

        &-top {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

        }

        &-img {
          padding: 5px 0;

          &-one {
            margin-right: 10px;
          }
        }

        &-bottom {
          padding: 0 5px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

        }
      }
    }
  }
}

.bodyList {
  width: 100%;
  height: 100%;
  background: white;
  padding: 4px 5px;

  &-every {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e9e9e9;

    &-top {
      display: flex;
      align-items: center;

      &-name {
        margin-left: 10px;
        font-size: 12px;
        display: flex;
        flex-direction: column;

        &-one {
          font-weight: 600;
        }
      }
    }

    &-main {
      font-size: 12px;
      padding: 0 5px;

      &-top {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

      }

      &-img {
        padding: 5px 0;

        &-one {
          margin-right: 10px;
        }
      }

      &-bottom {
        padding: 0 5px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

      }
    }
  }
}

.container-shopList {
  margin-top: 0.2703rem;
  padding: 0.1351rem 0.1351rem 0;
  background: #fff;

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-name {
      height: 1.4rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // font-size: 0.2rem;
      color: #333;
      // transform: scale(.7);
      margin-left: 8px;

      &-one {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-platform {
          // background: #DA0B06;
          // color: white;
          // font-size: 0.3243rem;
          font-weight: 700;
          font-size: 14px;
          // border-radius: 999px;
          // padding: 0 0.1351rem;
        }

        &-arrow {
          display: flex;
          align-items: center;
        }

        &-arrow span:first-child {
          margin-right: 0.1351rem;
        }
      }

      &-two {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .span {
          margin: 0 0.3rem;
        }

        &-color {
          color: #DA0B06;
        }
      }

    }

  }

  &-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2703rem;

    &-item span:first-child {
      font-size: 0.3784rem;
      color: #999;
    }

    &-item span:last-child {
      font-size: 0.3784rem;
      color: #F52556;
    }
  }

  &-main>span {
    color: #999;
  }

  &-footer {
    margin-top: 0.2703rem;
    display: flex;
    justify-content: space-between;

    &-item {
      width: 2.2162rem;
      height: 165;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-title {
        font-size: 0.3784rem;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}

.commodityDetail {
  &-title {
    display: flex;
    font-size: 0.4054rem;
    color: #000;
    justify-content: center;
    align-items: center;
    padding: 0.2703rem 0;

    div {
      border-top: 1px solid #000;
      width: 2.7027rem;
      height: 0;
    }

    span {
      margin: 0 0.2703rem;
    }
  }

  &-img {
    img {
      vertical-align: middle;
    }
  }
}

.vanGoodsAction {
  padding-bottom: 1.6216rem;
  .van-goods-action {
    height: 1.3514rem;
  }
  .van-goods-action-icon {
    background: #fff;
    min-width: 1.0811rem;
  }
  .addCart {
    background: transparent;
    color: #E54320;
    border: 1px solid red;
  }
}
</style>